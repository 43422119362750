import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="px-16 py-6 flex items-center font-semibold bg-black">
      <Link to="/in/privacy">
        <div className="mx-5 text-white cursor-pointer">privacy</div>
      </Link>
      <div className="mx-5 text-white">terms</div>
      <div className="mx-5 text-white">faqs</div>
    </div>
  );
}

export default Footer;
