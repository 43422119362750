import React from "react";
import Header from "../../Component/Common/Header";
import { Link } from "react-router-dom";

function Contact() {
  return (
    <div className="bg-black min-h-screen">
      <Header />
      <div className="px-36 py-14 max-[1200px]:px-20 max-sm:px-3">
        <div>
          <h1 className="font-bold text-8xl text-white">
            Contact <span className="text-[#FFC300]">us</span>
          </h1>
          <div className="mx-2 mt-6">
            <div className="text-white/70 my-1">
              Please fill out form below if you have any question for us.
            </div>
            <div className="text-white/70">
              We typically try to response within 48 hours
            </div>
          </div>
        </div>
        <div className="mt-14 flex items-start max-[1200px]:flex-col-reverse">
          <div className="w-1/3 mx-4 flex flex-col justify-between h-[350px] max-[1200px]:mt-6">
            <div>
              <h1 className="text-sm text-white/80">Email Address</h1>
              <h2 className="text-lg text-white">biz.ruffelo@gmail.com</h2>
            </div>
            <div>
              <div className="py-6 flex items-start flex-col  bg-black">
                <div className="text-white">Link</div>
                <Link to="/in/privacy">
                  <div className="text-white/80 my-2 cursor-pointer">privacy</div>
                </Link>
                <div className="text-white/80 my-2">terms</div>
                <div className="text-white/80 my-2">faqs</div>
              </div>
            </div>
          </div>
          <div className="w-2/3 ml-6 max-[1100px]:w-full max-sm:ml-0 max-sm:px-2">
            <div className="py-10 px-5 bg-[#f0eeef] max-sm:px-0">
              <div className="w-full flex items-center max-sm:flex-col">
                <div className="w-1/2  px-6 max-md:w-full">
                  <label htmlFor="" className="text-lg font-semibold">
                    Full Name
                  </label>
                  <div className="mt-1 w-full">
                    <input
                      type="text"
                      className="outline-none h-10 border-b text-base border-black bg-transparent w-full"
                      placeholder="Enter your full name..."
                    />
                  </div>
                </div>
                <div className="w-1/2 px-6 max-md:w-full">
                  <label htmlFor="" className="text-lg font-semibold">
                    Email
                  </label>
                  <div className="mt-1 w-full ">
                    <input
                      type="text"
                      className="outline-none border-b h-10 text-base border-black bg-transparent w-full"
                      placeholder="Enter your email address.."
                    />
                  </div>
                </div>
              </div>
              <div className="w-full flex items-center mt-6  max-sm:flex-col">
                <div className="w-1/2  px-6 max-md:w-full">
                  <label htmlFor="" className="text-lg font-semibold">
                    Phone Number
                  </label>
                  <div className="mt-1 w-full">
                    <input
                      type="text"
                      className="outline-none h-10 border-b text-base border-black bg-transparent w-full"
                      placeholder="Enter your full name..."
                    />
                  </div>
                </div>
              </div>
              <div className="w-full flex items-end justify-end mt-6 ">
                <div className="w-full px-6">
                  <label htmlFor="" className="text-lg font-semibold">
                    Message
                  </label>
                  <div className="mt-1 w-full ">
                    <input
                      type="text"
                      className="outline-none border-b h-10 text-base border-black bg-transparent w-full"
                      placeholder="Type your message"
                    />
                  </div>
                  <div className="flex items-end justify-end">
                    <div className="bg-[#FFC300] py-3 px-7 mt-8 font-semibold rounded-full">
                      Send message
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
