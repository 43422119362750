import React from "react";
import Header from "../../Component/Common/Header";

function Account() {
  return (
    <div className="bg-black w-full min-h-screen">
      <Header />
      <div className="h-[500px] flex items-center justify-center">
        <div className="px-9 py-9 bg-[#0f0f0f] w-1/3 rounded-lg">
          <div className="text-2xl text-white">
            Are you sure you want to delete your account?
          </div>
          <div className="bg-red-400 py-3 px-5 mt-7 text-center rounded-md">
            delete account
          </div>
        </div>
      </div>
      <div className="px-16 py-6 flex items-center font-semibold bg-black">
        <div className="mx-5 text-white">privacy</div>
        <div className="mx-5 text-white">terms</div>
        <div className="mx-5 text-white">faqs</div>
      </div>
    </div>
  );
}

export default Account;
