import React from "react";
import Header from "../../Component/Common/Header";
import Footer from "../../Component/Common/Footer";

function About() {
  return (
    <div className="bg-black w-full min-h-screen">
      <Header />
      <div className=" w-full flex py-8 pb-16 px-28 max-[760px]:px-6 max-sm:px-6">
        <div className="text-2xl">
          <div>
            <img
              src="https://images.pexels.com/photos/5082579/pexels-photo-5082579.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              alt=""
              className="h-[300px] w-full object-center object-cover rounded-lg"
            />
          </div>
          <div className="text-white/90 my-4">
            Ruffelo is your go-to platform for earning extra cash while enjoying
            your favorite online activities. We believe in rewarding you for
            your time and attention. Our mission is to make it easy and fun to
            earn rewards.
          </div>
          <div className="text-white/90 my-4">
            With Ruffelo, you can effortlessly make money by completing simple
            tasks like installing apps, taking surveys, or watching short
            videos. We offer a wide range of tasks to suit your interests and
            preferences.
          </div>
          <div className="text-white/90 my-4">
            Our user-friendly app and dedicated support team are committed to
            providing you with a seamless and enjoyable experience. Join the
            Ruffelo community today and start earning!
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
