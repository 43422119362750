import React from "react";
import Logo from "../../assets/image/whitelogo-symbol.png";
import { Link } from "react-router-dom";

function Header() {
  return (
    <div className="py-6 px-16 max-sm:px-6">
      <div className="flex items-center justify-between">
        <Link to="/">
          <div className="flex items-center">
            <img src={Logo} alt="logo" className="w-12" />
          </div>
        </Link>
        <div className="flex items-center  max-[720px]:hidden">
          <Link to="/in/about">
            <div className="text-white/90 mx-6 cursor-pointer">About</div>
          </Link>
          <Link to="/in/blogs">
            <div className="text-white/90 mx-6">blogs</div>
          </Link>
          <Link to="/contact">
            <div className="text-white/90 mx-6">Contact us</div>
          </Link>
        </div>
        <div>
          <div className="py-2.5 px-5 rounded-full border-[2px] border-[#FFC300] text-white text-sm">
            Get Ruffelo
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
