import { AiOutlineDoubleRight } from "react-icons/ai";
import Header from "../../Component/Common/Header";
import { blogs } from "../../Container/Data/Blog";
import Footer from "../../Component/Common/Footer";

function BLogs() {
  return (
    <>
      <div className="w-full min-h-screen bg-black">
        <Header />
        <div className="px-8  w-full blog_containe max-sm:px-4">
          <div className="flex items-start w-full py-8 flex-wrap">
            {blogs?.slice(0, 3)?.map((blog: any, index: number) => (
              <div
                key={index}
                onClick={() => {
                  window.location.assign(
                    `/blog/${blog?.title?.split(" ")
                      ?.join("-")
                      ?.split("?")[0]
                      .split(" ")
                      ?.join("-")}/?blog_id=${blog.id}`
                  );
                }}
                className="w-1/3 py-6 blog_main_container max-[970px]:w-1/2 max-md:w-full"
              >
                <div className="pr-6 max-sm:pr-0 cursor-pointer w-full blog_main_container_clm">
                  <div className="w-full blog_main_image_container h-80  max-[500px]:h-44">
                    <img
                      src={blog.image}
                      alt=""
                      className="w-full h-full object-cover object-top rounded-md  max-[500px]:object-center"
                    />
                  </div>
                  <div className="w-full mt-3 blog_main_container_clm f_w ">
                    <div className="w-full h-full">
                      <div className="text-white blog_clm_title text-2xl font-bold">
                        {blog?.title}
                      </div>

                      <div className="text-slate-200 blog_clm_content text-lg font-thin">
                        {/* {_blogContentFilter(blog?.blog_content).slice(0, 120)} */}
                      </div>
                      <div className="flex items-center flex-wrap pt-6 justify-end">
                        <div className="border cursor-pointer border-slate-100 rounded-full py-2 px-6 text-sm text-white/70 flex items-center justify-center">
                          Read More <AiOutlineDoubleRight className="ml-2" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="w-full py-5">
            <div className="w-2/3 font-bold max-sm:text-5xl uppercase text-6xl text-white font_s_1 wid">
              More from Ruffelo
            </div>
            <div className="flex items-start w-full py-8 flex-wrap f_w">
              {blogs?.slice(3)?.map((blog: any, index: number) => (
                <div
                  key={index}
                  onClick={() => {
                    window.location.assign(
                      `/blog/${blog?.title
                        ?.split(" ")
                        .join("-")
                        .slice(0, 5)}?blog_id=${blog.id}`
                    );
                  }}
                  className="w-1/3 py-6 blog_other_main_container max-[970px]:w-1/2 max-md:w-full"
                >
                  <div className="pr-6 max-sm:pr-0 cursor-pointer w-full">
                    <div className="w-full h-80 max-sm:h-64 wid">
                      <img
                        src={blog.image}
                        alt=""
                        className="w-full h-full object-cover object-top "
                      />
                    </div>
                    <div className="w-full mt-3">
                      <div className="w-full h-full">
                        <div className="text-white text-2xl font-bold">
                          {blog?.title}
                        </div>

                        <div className="text-slate-200 text-lg font-thin">
                          {/* {_blogContentFilter(blog?.blog_content).slice(0, 120)} */}
                        </div>
                        <div className="flex items-center flex-wrap pt-6 justify-end">
                          <div className="border border-slate-100 rounded-full py-2 px-6 text-sm text-white/70 flex items-center justify-center">
                            Read More <AiOutlineDoubleRight className="ml-2" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default BLogs;
