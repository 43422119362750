import React, { useEffect, useState } from "react";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import { blogs } from "../../Container/Data/Blog";
import Header from "../../Component/Common/Header";
import Footer from "../../Component/Common/Footer";

function BlogDetail() {
  const [currentBlog, setCurrentBlog] = useState<any>({});
  const [moreBlog, setMoreBlog] = useState<any>([]);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("blog_id");
    setMoreBlog(blogs?.filter((state: any) => state?.id != id));
    document.getElementsByClassName("astro_blog_content")[0].innerHTML =
      blogs?.filter((state: any) => state?.id == id)[0]?.content;
    setCurrentBlog(blogs?.filter((state: any) => state?.id == id)[0]);
  }, [blogs?.length]);

  return (
    <div className="w-full min-h-screen bg-black">
      <Header />
      <div className="px-12 max-md:px-4 b-s-container flex  justify-center max-lg:flex-wrap relative pad_n">
        <div
          className="blogcontainers max-lg:w-full w-2/3  py-8 px-6 max-md:px-3 pad_2 max-sm:px-0
        "
        >
          <div
            className="text-4xl max-md:text-3xl text-white font-bold "
            style={{
              wordSpacing: "8px",
            }}
          >
            {currentBlog?.title}
          </div>

          <div>
            <div className="flex items-center justify-between">
              <div className="font-thin text-sm my-5">December 16, 2023</div>
              <div className="flex items-center my-4">
                <div className="mx-4 flex items-center">
                  <div className="flex items-center mx-1">
                    <FacebookShareButton url={window.location.href}>
                      <FacebookIcon size={32} round />
                    </FacebookShareButton>
                  </div>
                  <div className="flex items-center  mx-1">
                    <TwitterShareButton
                      url={window.location.href}
                      title={currentBlog?.title}
                    >
                      <TwitterIcon size={32} round />
                    </TwitterShareButton>
                  </div>
                  <div className="flex items-center  mx-1">
                    <LinkedinShareButton url={window.location.href}>
                      <LinkedinIcon size={32} round />
                    </LinkedinShareButton>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full h-[300px] max-md:h-[230px]">
              <img
                className="w-full h-full object-cover rounded-lg"
                src={currentBlog.image}
                alt="blog_image"
              />
            </div>
            <div className=" py-3" style={{}}>
              <div className="text-justify text-xl text-white/80 indianZodiac_content">
                <div className="astro_blog_content my-5 text-justify"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/3 sticky mt-8 max-lg:w-full max-lg:mx-7 pl-7 mr max-md:w-full max-md:pl-0 max-md:mx-0">
          <div className="">
            <div
              className="text-white font-bold text-2xl"
              style={{
                fontFamily: `'Montserrat', sans-serif`,
                wordSpacing: "8px",
              }}
            >
              Latest article
            </div>
            <div>
              {moreBlog?.slice(0, 4)?.map((blog: any, index: number) => (
                <>
                  <div
                    key={index}
                    onClick={() => {
                      window.location.assign(
                        `/blog/${blog?.Blog_heading?.split(" ")
                          ?.join("-")
                          ?.split("?")[0]
                          .split(" ")
                          ?.join("-")}/?blog_id=${blog.id}`
                      );
                    }}
                    className="flex items-start my-3 py-2 cursor-pointer max-"
                  >
                    <div>
                      <img
                        src={blog.image}
                        alt="blogimage"
                        className="w-24 h-20 object-cover rounded-lg"
                      />
                    </div>
                    <div className="text-white/70 text-base font-semibold mx-3 w-10/12 a_center ">
                      {blog?.title}
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default BlogDetail;
